import React from 'react'
import Layout from '../components/Layout'

export default class EventPage extends React.Component {
  render() {
    

    return (
      <Layout>
         <div className="hero is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-two-thirds">
                <h1 className="title"> Explore our community events </h1>
                <h2 className="subtitle"> Choose your interest, dive now in tech world!!!
              </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section is-storyworlds has-background is-medium">
        <div className="container">
          <div className="columns">
            <div className="column is-3 is-2-widescreen">
              <div className="menu">
                <p className="menu-label"> Filter by host </p>
                <ul className="menu-list">
                  <li> <a className="is-active" data-action="filter" data-group="" href="">All</a>
                  </li>
                  <li> <a data-action="filter" data-group="2-4" href="">Host 1
                      </a> </li>
                  <li> <a data-action="filter" data-group="4-6" href="">Host 2
                      </a> </li>
                  <li> <a data-action="filter" data-group="6-9" href="">Host 3
                      </a> </li>
                  <li> <a data-action="filter" data-group="9-12" href="">Host 4
                      </a> </li>
                </ul>
              </div>
            </div>

            <div className="column is-9 is-10-widescreen">
              <div className="columns is-multiline" id="grid">
                <div className="column is-6 is-4-widescreen is-flex" data-groups="[&quot;6-9&quot;]">
                  <div className="card is-storyworld"> <a className="card-header" href="">
                      <div className="card-image" ></div>
                    </a>
                    <div className="card-body"> <a href="">
                        <div className="card-title"> Event 1 </div>
                      </a>
                      <div className="card-meta"> <i className="icon-user"></i> by  </div>
                      <div className="card-description">
                        <p>Description!</p>
                      </div>
                    </div>
                    <div className="card-footer"> <a className="button is-primary is-outlined" href="">Register
                        now</a> </div>
                  </div>
                </div>
                <div className="column is-6 is-4-widescreen is-flex" data-groups="[&quot;6-9&quot;]">
                  <div className="card is-storyworld"> <a className="card-header" href="">
                      <div className="card-image" ></div>
                    </a>
                    <div className="card-body"> <a href="">
                        <div className="card-title"> Event 1 </div>
                      </a>
                      <div className="card-meta"> <i className="icon-user"></i> by  </div>
                      <div className="card-description">
                        <p>Description!</p>
                      </div>
                    </div>
                    <div className="card-footer"> <a className="button is-primary is-outlined" href="">Register
                        now</a> </div>
                  </div>
                </div><div className="column is-6 is-4-widescreen is-flex" data-groups="[&quot;6-9&quot;]">
                  <div className="card is-storyworld"> <a className="card-header" href="">
                      <div className="card-image" ></div>
                    </a>
                    <div className="card-body"> <a href="">
                        <div className="card-title"> Event 1 </div>
                      </a>
                      <div className="card-meta"> <i className="icon-user"></i> by  </div>
                      <div className="card-description">
                        <p>Description!</p>
                      </div>
                    </div>
                    <div className="card-footer"> <a className="button is-primary is-outlined" href="">Register
                        now</a> </div>
                  </div>
                </div><div className="column is-6 is-4-widescreen is-flex" data-groups="[&quot;6-9&quot;]">
                  <div className="card is-storyworld"> <a className="card-header" href="">
                      <div className="card-image" ></div>
                    </a>
                    <div className="card-body"> <a href="">
                        <div className="card-title"> Event 1 </div>
                      </a>
                      <div className="card-meta"> <i className="icon-user"></i> by  </div>
                      <div className="card-description">
                        <p>Description!</p>
                      </div>
                    </div>
                    <div className="card-footer"> <a className="button is-primary is-outlined" href="">Register
                        now</a> </div>
                  </div>
                </div>
                <div className="column is-6 is-4-widescreen is-flex" data-groups="[&quot;6-9&quot;]">
                  <div className="card is-storyworld"> <a className="card-header" href="">
                      <div className="card-image" ></div>
                    </a>
                    <div className="card-body"> <a href="">
                        <div className="card-title"> Event 1 </div>
                      </a>
                      <div className="card-meta"> <i className="icon-user"></i> by  </div>
                      <div className="card-description">
                        <p>Description!</p>
                      </div>
                    </div>
                    <div className="card-footer"> <a className="button is-primary is-outlined" href="">Register
                        now</a> </div>
                  </div>
                </div>
                <div className="column is-6 is-4-widescreen is-flex" data-groups="[&quot;6-9&quot;]">
                  <div className="card is-storyworld"> <a className="card-header" href="">
                      <div className="card-image" ></div>
                    </a>
                    <div className="card-body"> <a href="">
                        <div className="card-title"> Event 1 </div>
                      </a>
                      <div className="card-meta"> <i className="icon-user"></i> by  </div>
                      <div className="card-description">
                        <p>Description!</p>
                      </div>
                    </div>
                    <div className="card-footer"> <a className="button is-primary is-outlined" href="">Register
                        now</a> </div>
                  </div>
                </div>
              
                </div>
            </div>
          </div>
        </div>
      </section>
      </Layout>
    )
  }
}


